<template>
  <div>
    <div
      class="
        bg_col_fff
        bor_rad_8
        h_100_vw
        box_sha_0_0_8_8_black_80_per
        m_0_16 m_t_16
      "
    >
      <div class="h_100_per dis_flex flex_dir_col p_16">
        <div class="dis_flex ali_it_cen">
          <div class="flex_1 font_16 font_bold">订单状态统计图</div>

          <!--  -->
        </div>

        <!-- 图表 -->
        <Chart
          v-if="!loading"
          ref="chart"
          class="flex_1 z_0 m_t_8"
          :options="options"
        />
        <div v-else class="flex_1 dis_flex ju_con_cen ali_it_cen">
          <van-loading type="spinner" />
        </div>

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!--  -->
  </div>
</template>

<script>
import { columnAmount } from '@/utils/tools';

import { brokerDashboardOrderTrendChartStatisticsPost } from '@/api/index';

export default {
  components: {
    Chart: () => import('@/components/Chart.vue'),
  },
  data() {
    return {
      loading: false,
      options: {},

      governorList: [],
      brokerProductList: [],

      scrollTop: 0,

      //
    };
  },
  computed: {},
  mounted() {
    this.getTrendChart();

    //
  },
  destroyed() {},
  methods: {
    columnAmount,

    // 获取 走势图
    async getTrendChart() {
      this.loading = true;

      const data = await brokerDashboardOrderTrendChartStatisticsPost();

      this.loading = false;

      // 柱状图
      this.options = {
        grid: {
          left: '1%',
          top: '4%',
          right: '4%',
          bottom: '4%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          confine: true,
          formatter: (e) => {
            const o = e[0];
            return [
              `<b>${o.name}</b>`,
              `数量：${columnAmount(o.value, { fixed: 0 })}`,
            ].join('<br />');
          },
        },
        xAxis: {
          type: 'value',
          minInterval: 1,
        },
        yAxis: {
          type: 'category',
          axisLabel: {
            fontSize: 10,
          },
          data: data.map((it) => it.label),
        },
        series: [
          {
            type: 'bar',
            barWidth: '70%',
            itemStyle: {
              barBorderRadius: [0, 4, 4, 0],
              color: new window.echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 0,
                  color: '#91CAFA',
                },
                {
                  offset: 1,
                  color: '#2396F5',
                },
              ]),
            },
            data: data.map((it) => it.orderCount),
          },
        ],
      };

      //
    },

    //
  },
};
</script>
